.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-upload {
  border: 2px dashed #ccc;
  padding: 20px;
  transition: background-color 0.3s ease;
}

.file-upload.dragging {
  background-color: #f8f8f8;
}

.file-upload__drop-area {
  text-align: center;
  margin-bottom: 5px;
}

.file-upload__file-list {
  display: flex;
  flex-wrap: wrap;
}

.file-upload__file {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.file-upload__file-preview {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-bottom: 5px;
}

.file-upload__file-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-upload__file-name {
  font-size: 12px;
  text-align: center;
  word-break: break-word;
}

.file-upload__file-remove button {
  background-color: transparent;
  border: none;
  color: #999;
  font-size: 12px;
  cursor: pointer;
}

.file-upload__upload-button {
  text-align: center;
  margin-top: 10px;
}

.file-upload__upload-button button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 8px 20px;
  font-size: 14px;
  cursor: pointer;
}
